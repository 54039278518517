import React from 'react'
import Layout from '../components/layout'

class Impressum extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout seoTitle="Impressum | Praxis für Psychotherapie Telgte">
        <div id="main">
          <section id="intro" className="main">
            <div>
              <header className="major">
                <h2>Impressum</h2>
              </header>
              <div>
                <p>
                  <strong>Dipl.-Psych. Angelika Behrenberg</strong>
                  <br />
                  <a href="http://www.psychotherapie-telgte.de/">
                    www.psychotherapie-telgte.de
                  </a>
                  <br />
                  Münsterstr. 16, 48291 Telgte <br />
                  Fon: 02504/739595 <br />
                  Fax: 02504/739597
                </p>
                <p>
                  Webmaster:{' '}
                  <a href="mailto:webmaster@tfbs.de">webmaster(at)tfbs.de</a>
                </p>
                <p>
                  <b>Urheberrecht</b>
                  <br />
                  Die durch den Betreiber dieser Webseite erstellten Inhalte und
                  Werke auf den Seiten der Webseite unterliegen dem deutschen
                  Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung
                  und jede Art der Verwertung außerhalb der durch das
                  Urheberrecht explizit erlaubten Verwendungen erfordern die
                  schriftliche Zustimmung des Zuständigen. Soweit diese Webseite
                  Inhalte enthält, die nicht vom Betreiber der Webseite erstellt
                  wurden, wird den Urheberrechten Dritter Rechnung getragen und
                  Inhalte Dritter sind entsprechend gekennzeichnet. Sollten Sie
                  auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir
                  um einen Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                  werden wir die entsprechenden Inhalte umgehend entfernen.
                </p>
                <p>
                  <b>Widerspruch Werbe-Mails</b>
                  <br />
                  Der Nutzung von Kontaktdaten (die im Rahmen der
                  Impressumspflicht veröffentlicht wurden) zur Übersendung von
                  nicht ausdrücklich angeforderter Werbung und Informationen
                  wird hiermit widersprochen. Der Seitenbetreiber behält sich
                  rechtliche Schritte bei unverlangter Zusendung von
                  Werbeinformationen, insbesondere bei Spam-E-Mails, vor.
                </p>
                <h3>
                  <b>Nutzungsbedingungen, Haftungsausschluss</b>
                </h3>
                <p>
                  <b>Inhalte dieses Internetauftritts</b>
                  <br />
                  Der Autor übernimmt keinerlei Gewähr für die bereitgestellten
                  Informationen; das betrifft insbesondere Termine, Orte,
                  Preisangaben, Aktualität, Korrektheit und Vollständigkeit.
                  Haftungsansprüche gegen den Autor – gleich ob aus Schäden
                  materieller oder ideeller Art und gleich ob aus Nutzung oder
                  Nicht-Nutzung der bereitgestellten Informationen – sind
                  grundsätzlich ausgeschlossen, sofern seitens des Autors kein
                  nachweislich vorsätzliches oder grob fahrlässiges Verschulden
                  vorliegt. Die Nutzung oder Nichtnutzung der bereitgestellten
                  Informationen geschieht in jedem Fall auf eigene Gefahr. Alle
                  Angebote sind freibleibend und unverbindlich. Der Autor behält
                  es sich ausdrücklich vor, den gesamten Internetauftritt oder
                  auch Teile davon ohne gesonderte Ankündigung zu verändern, zu
                  ergänzen, zu löschen oder zeitweise oder endgültig zu
                  schließen.
                </p>
                <p>
                  <b>Datenschutz</b>
                  <br />
                  Im Internetauftritt besteht von Zeit zu Zeit die Möglichkeit
                  der Eingabe persönlicher Daten (Name, Firma, postalische und
                  eMail-Adresse, etc.). Eine Weitergabe der bereitgestellten
                  Informationen an Dritte erfolgt nicht. Der Bitte um Löschung
                  bereitgestellter Informationen wird jederzeit sofort
                  nachgekommen; dazu reicht eine entsprechende eMail an
                  webmaster@tfbs.de Die Nutzung aller in diesem Internetauftritt
                  veröffentlichten Kontaktdaten durch Dritte zur Übersendung von
                  nicht ausdrücklich angeforderten Informationen ist nicht
                  gestattet. Bei Verstößen gegen dieses Verbot sind rechtliche
                  Schritte ausdrücklich vorbehalten. Die Betreiber dieser Seiten
                  nehmen&nbsp;den Schutz Ihrer persönlichen Daten sehr ernst.
                  Wir behandeln Ihre personenbezogenen Daten&nbsp;vertraulich
                  und entsprechend der gesetzlichen Datenschutzvorschriften
                  sowie dieser&nbsp;Datenschutzerklärung. Die Nutzung unserer
                  Website ist in der Regel ohne Angabe&nbsp;personenbezogener
                  Daten möglich. Soweit auf unseren Seiten personenbezogene
                  Daten&nbsp;(beispielsweise Name, Anschrift oder
                  E-Mail-Adressen) erhoben werden, erfolgt dies,
                  soweit&nbsp;möglich, stets auf freiwilliger Basis. Diese Daten
                  werden ohne Ihre ausdrückliche Zustimmung&nbsp;nicht an Dritte
                  weitergegeben. Wir weisen darauf hin, dass die
                  Datenübertragung im Internet&nbsp;(z.B. bei der Kommunikation
                  per E-Mail) Sicherheitslücken aufweisen kann. Ein
                  lückenloser&nbsp;Schutz der Daten vor dem Zugriff durch Dritte
                  ist nicht möglich.
                </p>
                <p>
                  <b>Rechte Dritter</b>
                  <br />
                  Der Autor ist bestrebt, in allen Publikationen die
                  Urheberrechte Dritter zu beachten. Insbesondere bei Marken-
                  oder Warenzeichen bedeutet eine bloße Nennung ohne Verweis auf
                  evtl. Rechteinhaber nicht, dass sie nicht durch Rechte Dritter
                  geschützt sind. Das Copyright für vom Autor selbst erstellte
                  Objekte jeder Art verbleibt allein beim Autor dieses
                  Internetauftritts. Eine Vervielfältigung oder Verwendung
                  dieser Objekte (seien es Grafiken, Texte, Ton- oder
                  Videodateien), gleich mit welchem Medium, ist ohne
                  ausdrückliche schriftliche Zustimmung des Autors nicht
                  gestattet.
                </p>
                <p>
                  <b>Google Schriftarten</b>
                  <br />
                  Diese Seite nutzt zur Darstellung bestimmte Schriftarten von
                  Google. Beim Aufruf einer Seite lädt Ihr Browser diese
                  Schriftarten. Dabei wird Ihre IP-Adresse samt der Seite
                  (Internetadresse), die Sie besucht haben, an einen Server der
                  Firma Google übertragen. Weitere Informationen zu diesen
                  Google Schriftarten finden Sie unter{' '}
                  <a
                    href="https://developers.google.com/fonts/faq"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://developers.google.com/fonts/faq
                  </a>{' '}
                  und in der Datenschutzerklärung von Google unter{' '}
                  <a
                    href="https://www.google.com/policies/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.google.com/policies/privacy/
                  </a>
                </p>
                <p>
                  <b>
                    Auskunftsrecht, Recht auf Berichtigung, Sperrung, Löschung
                  </b>
                  <br />
                  Auf schriftliche Anfrage informieren wir Sie über die zu Ihrer
                  Person durch uns gespeicherten Daten. Sie haben auch ein Recht
                  auf Berichtigung, Sperrung oder Löschung dieser Daten. Die
                  Anfrage ist an unsere im Impressum der Webseite angegebene
                  Adresse zu richten.
                </p>
                <p>
                  <b>Widerrufsrecht</b>
                  <br />
                  Sie haben jederzeit die Möglichkeit, der Erhebung,
                  Verarbeitung und Nutzung Ihre Daten zu widersprechen und/oder
                  Ihre Einwilligung zur Erhebung, Verarbeitung und Weitergabe
                  Ihrer personenbezogenen Daten uns gegenüber mit Wirkung für
                  die Zukunft zu widerrufen. Daten für Abrechnungs- und
                  buchhalterische Zwecke sind hiervon nicht berührt. Wenden Sie
                  sich hierzu bitte an unsere im Impressum der Webseite
                  angegebene Adresse.
                </p>
                <p>
                  <b>Allgemeine Erhebung von Daten</b>
                  <br />
                  Wenn Sie auf unsere Webseite zugreifen oder eine Datei
                  abrufen, werden Daten über diesen Vorgang in einer
                  Protokolldatei auf unserem Webserver gespeichert. Im Einzelnen
                  können folgende Daten gespeichert werden:
                </p>
                <ul>
                  <li>
                    IP-Adresse (wenn möglich, wird diese anonymisiert übertragen
                    oder gespeichert)
                  </li>
                  <li>Domain-Name der Webseite, von der Sie kamen</li>
                  <li>Namen der abgerufenen Dateien</li>
                  <li>Datum und Uhrzeit eines Abrufs</li>
                  <li>Name Ihres Internet Service Providers</li>
                  <li>
                    sowie ggf. Betriebssystem und Browserversion Ihres Endgeräts
                  </li>
                </ul>
                <p>
                  IP-Adressen speichern wir, wenn überhaupt, über einen Zeitraum
                  von wenigen Tagen. Die Speicherung erfolgt aus
                  Datensicherheitsgründen, um die Stabilität und die Sicherheit
                  unseres Systems zu gewährleisten. Die statistische Auswertung
                  anonymisierter Datensätze bleibt vorbehalten.
                </p>
                <p>
                  <b>Kontaktformulare</b>
                  <br />
                  Wenn Sie uns per Kontaktformular Anfragen zukommen lassen,
                  werden Ihre Angaben aus dem Anfrageformular inklusive der von
                  Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der
                  Anfrage und für den Fall von Anschlussfragen bei uns
                  gespeichert und verarbeitet. Sie können dem jederzeit
                  widersprechen (Widerrufsrecht). Ihre Daten geben wir nicht
                  ohne Ihre Einwilligung weiter.
                </p>
                <p>
                  <b>Newsletter</b>
                  <br />
                  Wir versenden Newsletter mit werblichen Informationen nur mit
                  der Einwilligung der Empfänger oder aufgrund einer
                  gesetzlichen Erlaubnis. Die Anmeldung zu unserem Newsletter
                  erfolgt über ein Double-Opt-In-Verfahren: Sie erhalten nach
                  der Anmeldung eine E-Mail, in der Sie Ihre Anmeldung
                  bestätigen sollen. Diese Bestätigung ist notwendig, um Sie als
                  Inhaber der E-Mail zu verifizieren. Die Anmeldung zum
                  Newsletter wird protokolliert, um die Anmeldung gemäß den
                  rechtlichen Anforderungen nachweisen zu können. Dies umfasst
                  die Speicherung der Zeitpunkte der Anmeldung und Bestätigung
                  sowie Ihre IP-Adresse. Ebenso werden weitere Daten, die Sie
                  bei der Newsletter-Anmeldung angegeben haben, abgespeichert.
                  Ihre Informationen außer der E-Mail-Adresse nutzen wir
                  lediglich zur Personalisierung des Newsletters, dazu gehört
                  beispielsweise Ihr Name. Sie können den Erhalt des Newsletters
                  jederzeit kündigen. Einen Link zur Kündigung des Newsletters
                  finden Sie in jeder Newsletter-Mail.
                </p>
                <p>
                  <b>Änderungen dieser Datenschutzerklärung</b>
                  <br />
                  Wir behalten uns vor, diese Datenschutzerklärung zu ändern,
                  wenn sich die Rechtslage oder dieses Online-Angebot oder die
                  Art der Datenerfassung ändert. Dies gilt jedoch nur bezüglich
                  Erklärungen zur Datenverarbeitung. Sofern eine Einwilligung
                  des Nutzers notwendig ist oder Bestandteile der
                  Datenschutzerklärung eine Regelung des Vertragsverhältnisses
                  mit Nutzern enthalten, erfolgt die Änderung der
                  Datenschutzerklärung nur nach Zustimmung des Nutzers.
                </p>
                <p>
                  Bitte informieren Sie sich daher bei Bedarf über diese
                  Datenschutzerklärung, insbesondere, wenn Sie personenbezogene
                  Daten mitteilen.
                </p>
                <p>
                  <b>Sicherheitshinweis</b>
                  <br />
                  Wir sichern unsere Webseite und sonstige IT-Systeme durch
                  geeignete technische und organisatorische Maßnahmen gegen
                  Verlust, Zerstörung, unberechtigten Zugriff, unberechtigte
                  Veränderung oder unberechtigte Verbreitung Ihrer Daten ab. Ein
                  vollständiger Schutz gegen alle Gefahren ist praktisch trotz
                  aller Sorgfalt jedoch nicht in jedem Fall möglich. Weil bei
                  der Kommunikation per E-Mail die vollständige Datensicherheit
                  von uns nicht gewährleistet werden kann, empfehlen wir zur
                  Übermittlung von vertraulichen Informationen den Postweg.
                </p>
                <p>
                  <b>Cookies</b>
                  <br />
                  Unsere Internetseite verwendet Cookies. Ein Cookie ist eine
                  Textdatei, die beim Besuch einer Internetseite erstellt und
                  auf dem System des Nutzers der Website zwischengespeichert
                  wird. Wird der Server unserer Webseite erneut vom Nutzer der
                  Website aufgerufen, sendet der Browser des Nutzers der Website
                  den zuvor empfangenen Cookie wieder zurück an den Server. Der
                  Server kann die durch dieses Verfahren erhaltenen
                  Informationen auswerten. Durch Cookies können beispielsweise
                  Werbeeinblendungen gesteuert oder das Navigieren auf einer
                  Internetseite erleichtert werden. Cookies sind ferner
                  erforderlich, um die Funktionsfähigkeit unseres
                  Webseiten-Betriebs zu ermöglichen (als Rechtsgrundlage ist
                  Art. 6 Abs. 1 lit f DSGVO genannt, die Wahrung der
                  berechtigten Interessen des Betreibers dieser Webseite – wir
                  verwenden Cookies nur in Vereinbarung mit Art. 5 Abs. 1 lit a
                  DSGVO, also gemäß der Prinzipien „Rechtmäßigkeit, Verarbeitung
                  nach Treu und Glauben, Transparenz“). Wenn Sie die Nutzung von
                  Cookies unterbinden wollen, können Sie dies durch lokale
                  Einstellungen in Ihrem Internetbrowser, (z.B. Internet
                  Explorer, Mozilla Firefox, Opera oder Safari) tun.
                </p>
                <p>
                  <b>Recht auf Beschwerde bei einer Aufsichtsbehörde</b>
                  <br />
                  Jede betroffene Person hat unbeschadet eines anderweitigen
                  verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das
                  Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere
                  in dem Mitgliedstaat ihres Aufenthaltsorts, ihres
                  Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn
                  die betroffene Person der Ansicht ist, dass die Verarbeitung
                  der sie betreffenden personenbezogenen Daten gegen die
                  Datenschutzgrundverordnung der EU (DS-GVO) verstößt.
                </p>
                <p>
                  <b>Recht auf Datenübertragbarkeit</b>
                  <br />
                  Sie haben das Recht die Sie betreffenden personenbezogenen
                  Daten, die Sie uns bereitgestellt haben, in einem
                  strukturierten, gängigen und maschinenlesbaren Format zu
                  erhalten.
                </p>
                <p>
                  <b>Vertraulichkeit Ihres Kundenzugangs</b>
                  <br />
                  Wenn Sie Zugang zu einem geschützten Bereich auf unserer
                  Webseite erhalten haben, der durch ein Passwort gesichert ist,
                  sind Sie dafür verantwortlich, dieses Passwort vertraulich zu
                  behandeln. Wir bitten Sie, das Passwort niemandem mitzuteilen.
                </p>
                <p>
                  <b>Links zu anderen Webseiten</b>
                  <br />
                  Unsere Website/App kann von Zeit zu Zeit Links zu Websites
                  Dritter oder zu anderen Websites von uns selbst enthalten.
                  Wenn Sie einem Link zu einer dieser Websites folgen, weisen
                  wir Sie darauf hin, dass diese Websites ihre eigenen
                  Datenschutzrichtlinien haben und dass wir für diese
                  Richtlinien keine Verantwortung oder Haftung übernehmen. Bitte
                  überprüfen Sie diese Datenschutzrichtlinien, bevor Sie
                  personenbezogenen Daten an diese Websites weitergeben.
                </p>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Impressum
